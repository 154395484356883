<template>
  <div class="wrapper">
    <div class="p-lg-8 p-md-10">
      <Panel header="Licences and Insurance">
        <p class="p-text-justify">Please select the police licences that you have obtained to date from the list on
          the left, and your preferred level of insurance cover from the list on the right. Note that if you hold any
          target shooting licences, you are required to have at least third party liability insurance.</p>
        <br/>
        <form @submit.prevent="onSubmit">
          <div class="p-grid">
            <div class="p-md-6">
              <Fieldset legend="Licences">
                  <span v-for="licence in licences" :key="licence.id">
                    <div class="p-field-checkbox">
                      <Checkbox name="club"
                                :id="licence.id"
                                :value="licence.id"
                                v-model="selectedLicences"/>
                      <label :for="licence.id">{{ licence.name }}</label>
                    </div>
                  </span>
              </Fieldset>
            </div>
            <div class="p-md-6">
              <Fieldset legend="Insurance">
                <div class="p-field-checkbox">
                  <Checkbox name="thirdPartyInsurance"
                            id="thirdPartyInsurance"
                            :binary="true"
                            v-model="thirdPartyInsurance"/>
                  <label for="thirdPartyInsurance">Third Party Liability - €14.00</label>
                </div>
                <div class="p-field-checkbox">
                  <Checkbox name="personalAccidentInsurance"
                            id="personalAccidentInsurance"
                            :binary="true"
                            :disabled="insuredElsewhere"
                            v-model="personalAccidentInsurance"/>
                  <label for="personalAccidentInsurance">Personal Accident - €5.00</label>
                </div>
                <div class="p-field-checkbox">
                  <Checkbox name="insuredElsewhere"
                            id="insuredElsewhere"
                            :binary="true"
                            v-model="insuredElsewhere"/>
                  <label for="insuredElsewhere">Insured Elsewhere</label>
                  <InputText id="membershipNumber"
                             :disabled="!insuredElsewhere"
                             type="text"
                             class="p-ml-2"
                             v-model.trim="insuredElsewhereName"/>
                </div>
                <small v-if="insuranceRequiredButNotSelected" class="p-error">
                  Third party insurance is required for target shooting licence holders and RCOs.
                </small>
                <small v-if="insuredElsewhere && insuredElsewhereName === ''" class="p-error">
                  Please specify the name of the insurance agency.
                </small>
                <Divider/>
                <div align="right">
                  <br/><span style="font-weight: bold;">Total:</span> €{{ total.toFixed(2) }}
                </div>
              </Fieldset>
            </div>
          </div>
          <div align="right">
            <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel"/>
            <Button type="submit" label="Proceed"/>
          </div>
        </form>
      </Panel>
    </div>
  </div>
  <Toast/>
</template>

<script>
export default {
  data() {
    return {
      licences: [
        {id: "collector_a", name: "Collector Licence A"},
        {id: "collector_a_special", name: "Collector Licence A (Special)"},
        {id: "target_a", name: "Target Shooter Licence A"},
        {id: "target_a_special", name: "Target Shooter Licence A (Special)"},
        {id: "target_a_cb", name: "Target Shooter Licence A (Crossbow)"},
        {id: "rco", name: "Range Conducting Officer"},
        {id: "target_b_ts", name: "Target Shooter Licence B (Tactical Shotguns)"},
        {id: "target_b_ag", name: "Target Shooter Licence B (Air Guns)"},
        {id: "target_b_cp", name: "Target Shooter Licence B (Clay Pigeon)"},
        {id: "target_b_ml", name: "Target Shooter Licence B (Muzzle Loaders)"},
      ],
      insuredElsewhere: false,
      insuredElsewhereName: '',
      thirdPartyInsurance: true,
      personalAccidentInsurance: false,
      selectedLicences: []
    };
  },
  mounted() {
    this.$store.dispatch('members/fetchLatestLicences').then(data => {
      for (const [id, value] of Object.entries(data)) {
        if (value) {
          this.selectedLicences.push(id);
        }
      }
    }).catch(error => {
      console.log(error);
    });
  },
  methods: {
    onCancel() {
      this.$store.commit('members/resetRenewalInformation');
      this.$router.push({name: 'memberMain'});
    },
    onSubmit() {
      if (this.error || this.insuranceRequiredButNotSelected || (this.insuredElsewhere && this.insuredElsewhereName === '')) {
        return;
      }

      const licencesAndInsurance = {
        licences: this.selectedLicences,
        insurance: {
          'thirdParty': this.thirdPartyInsurance,
          'personalAccident': this.personalAccidentInsurance,
          'insuredElsewhere': {
            'hasInsuranceElsewhere': this.insuredElsewhere,
            'otherInsuranceAgencyName': this.insuredElsewhereName
          }
        }
      }
      this.$store.commit('members/addLicencesAndInsurance', licencesAndInsurance);

      this.$store.dispatch('members/prepareForCheckout').then(data => {
        window.location = data['redirect_url'];
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  },
  computed: {
    total() {
      if (this.insuredElsewhere) {
        return 0;
      }

      let total = 0;
      if (this.thirdPartyInsurance) {
        total += 14;
      }
      if (this.personalAccidentInsurance) {
        total += 5;
      }
      return total;
    },
    insuranceRequiredButNotSelected() {
      const hasTargetShooterLicence = this.selectedLicences.filter(l => l.startsWith("target") || l.startsWith("rco"));
      return (hasTargetShooterLicence.length > 0) && !(this.thirdPartyInsurance || this.insuredElsewhere);
    }
  },
  watch: {
    selectedLicences: {
      handler: function (newValue, oldValue) {
        const licenceAdded = newValue.filter(x => !oldValue.includes(x));
        if (licenceAdded.length === 0) {
          return;
        }

        let index = -1;
        switch (licenceAdded[0]) {
          case "collector_a":
            index = this.selectedLicences.indexOf("collector_a_special");
            break;
          case "collector_a_special":
            index = this.selectedLicences.indexOf("collector_a");
            break
          case "target_a":
            index = this.selectedLicences.indexOf("target_a_special");
            break;
          case "target_a_special":
            index = this.selectedLicences.indexOf("target_a");
            break;
        }
        if (index > -1) {
          this.selectedLicences.splice(index, 1);
        }
      },
      deep: true
    },
    insuredElsewhere: function (selected) {
      if (selected) {
        this.thirdPartyInsurance = false;
        this.personalAccidentInsurance = false;
      }
    },
    thirdPartyInsurance: function (selected) {
      if (selected) {
        this.insuredElsewhere = false;
      }
    }
  }
}
</script>
