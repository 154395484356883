<template>
  <div class="p-lg-12 p-md-12 p-sm-12">
    <Panel header="Add New Member">
      <div class="p-grid">
        <div class="p-md-12">
          <Fieldset legend="Personal Details">
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col-12 p-lg-4 p-md-4">
                <MemberPhoto id="photo" v-model="photo"/>
                <small v-if="v$.photo.$error" class="p-error">
                  Photo is required.
                </small>
              </div>
              <div class="p-lg-8 p-md-8">
                <div class="p-grid">
                  <div class="p-field p-col-12 p-md-2">
                    <label for="title">Title</label>
                    <InputText id="title"
                               type="text"
                               v-model.trim="title"
                               @blur="v$.title.$touch()"
                               :class="{ 'p-invalid': v$.title.$error }"/>
                    <small v-if="v$.title.$error" class="p-error">
                      Title is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-5">
                    <label for="firstName">First Name</label>
                    <InputText id="firstName"
                               type="text"
                               v-model.trim="firstName"
                               @blur="v$.firstName.$touch()"
                               :class="{ 'p-invalid': v$.firstName.$error }"/>
                    <small v-if="v$.firstName.$error" class="p-error">
                      First name is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-5">
                    <label for="lastName">LastName</label>
                    <InputText id="lastName"
                               type="text"
                               v-model.trim="lastName"
                               @blur="v$.lastName.$touch()"
                               :class="{ 'p-invalid': v$.lastName.$error }"/>
                    <small v-if="v$.lastName.$error" class="p-error">
                      Last name is required.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="dateOfBirth">Date of Birth</label>
                    <Calendar id="popup" class="form-element"
                              placeholder="dd/mm/yyyy"
                              dateFormat="dd/mm/yy"
                              :monthNavigator="true"
                              :yearNavigator="true"
                              :yearRange="yearRange"
                              v-model="dateOfBirth"
                              @blur="v$.dateOfBirth.$touch()"
                              :class="{ 'p-invalid': v$.dateOfBirth.$error }">
                    </Calendar>
                    <small v-if="v$.dateOfBirth.$error" class="p-error">
                      Date of birth is required and must be entered as dd/mm/yyyy.
                    </small>
                  </div>
                  <div class="p-field p-col-12 p-md-6">
                    <label for="idCardNumber">ID Card Number</label>
                    <InputText id="idCardNumber"
                               type="text"
                               v-model.trim="idCardNumber"
                               @blur="padIdCardNumber"
                               :class="{ 'p-invalid': v$.idCardNumber.$error }"/>
                    <small v-if="v$.idCardNumber.$error" class="p-error">
                      ID card number must be made up of maximum 7 digits followed by A, P, M, G, Z, H, B or L.
                    </small>
                  </div>
                </div>
              </div>
              <div class="p-field p-col-12 p-md-12">
                <label for="streetAddress">Street Address</label>
                <InputText id="streetAddress"
                           type="text"
                           v-model.trim="streetAddress"
                           @blur="v$.streetAddress.$touch()"
                           :class="{ 'p-invalid': v$.streetAddress.$error }"/>
                <small v-if="v$.streetAddress.$error" class="p-error">
                  Street address is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="town">Town/Village</label>
                <InputText id="town"
                           type="text"
                           v-model.trim="town"
                           @blur="v$.town.$touch()"
                           :class="{ 'p-invalid': v$.town.$error }"/>
                <small v-if="v$.town.$error" class="p-error">
                  Town/Village address is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="postCode">Post Code</label>
                <InputText id="postCode"
                           type="text"
                           v-model.trim="postCode"
                           @blur="v$.postCode.$touch()"
                           :class="{ 'p-invalid': v$.postCode.$error }"/>
                <small v-if="v$.postCode.$error" class="p-error">
                  Post code is required.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="mobileNumber">Mobile Number</label>
                <div class="p-inputgroup">
                  <Dropdown id="countryCode"
                            v-model="mobileNumberCountryCode"
                            :options="countries"
                            optionLabel="code"
                            optionValue="code"
                            :filter="true"
                            style="max-width: 120px">
                    <template #option="slotProps">
                      <div class="country-item-value">
                        <img src="../../../assets/images/flag_placeholder.png"
                             :class="'flag flag-' + slotProps.option.iso.toLowerCase()"/>
                        <span>{{ slotProps.option.code }}</span>
                      </div>
                    </template>
                  </Dropdown>
                  <InputText id="mobile-number"
                             type="number"
                             v-model.trim="mobileNumber"
                             :class="{'p-invalid':v$.mobileNumber.$error}"/>
                </div>
                <small v-if="v$.mobileNumber.$error" class="p-error">
                  Mobile number is invalid.
                </small>
              </div>
              <div class="p-field p-col-12 p-md-6">
                <label for="emailAddress">Email</label>
                <InputText id="emailAddress"
                           type="email"
                           v-model.trim="emailAddress"
                           @blur="v$.emailAddress.$touch()"
                           :class="{ 'p-invalid': v$.emailAddress.$error }"/>
                <small v-if="v$.emailAddress.$error" class="p-error">
                  A valid email address is required.
                </small>
              </div>
            </div>
          </Fieldset>
        </div>
        <div class="p-md-12">
          <Fieldset legend="Insurance">
            <div class="p-field-checkbox">
              <Checkbox name="thirdPartyInsurance"
                        id="thirdPartyInsurance"
                        :binary="true"
                        v-model="thirdPartyInsurance"/>
              <label for="thirdPartyInsurance">Third Party Liability - €14.00</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox name="personalAccidentInsurance"
                        id="personalAccidentInsurance"
                        :binary="true"
                        :disabled="insuredElsewhere"
                        v-model="personalAccidentInsurance"/>
              <label for="personalAccidentInsurance">Personal Accident - €5.00</label>
            </div>
            <div class="p-field-checkbox">
              <Checkbox name="insuredElsewhere"
                        id="insuredElsewhere"
                        :binary="true"
                        v-model="insuredElsewhere"/>
              <label for="insuredElsewhere">Insured Elsewhere</label>
              <InputText id="insuredElsewhereName"
                         :disabled="!insuredElsewhere"
                         type="text"
                         class="p-ml-2"
                         v-model.trim="insuredElsewhereName"/>
            </div>
            <small v-if="insuredElsewhere && insuredElsewhereName === ''" class="p-error">
              Please specify the name of the insurance agency.
            </small>
          </Fieldset>
        </div>
        <div class="p-md-12" align="right">
          <Button label="Cancel" class="p-button-secondary p-mr-2" @click="onCancel"/>
          <Button label="Submit" @click="onSubmit"/>
        </div>
      </div>
    </Panel>
  </div>
  <ConfirmDialog/>
  <Toast/>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import {email, numeric, required} from '@vuelidate/validators';

export default {
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    const today = new Date();
    return {
      enrollmentFee: 15.00,
      yearRange: `${today.getFullYear() - 100}:${today.getFullYear()}`,
      availableGenders: [
        {name: 'Male', code: 'M'},
        {name: 'Female', code: 'F'},
        {name: 'Unspecified', code: 'X'}
      ],
      countries: [],

      idCardNumber: null,
      title: null,
      firstName: null,
      lastName: null,
      dateOfBirth: null,
      streetAddress: null,
      town: null,
      postCode: null,
      mobileNumber: null,
      mobileNumberCountryCode: '+356',
      emailAddress: null,
      photo: null,

      insuredElsewhere: false,
      insuredElsewhereName: '',
      thirdPartyInsurance: true,
      personalAccidentInsurance: false,
    }
  },
  watch: {
    insuredElsewhere: function (selected) {
      if (selected) {
        this.thirdPartyInsurance = false;
        this.personalAccidentInsurance = false;
      }
    },
    thirdPartyInsurance: function (selected) {
      if (selected) {
        this.insuredElsewhere = false;
      } else {
        this.personalAccidentInsurance = false;
      }
    }
  },
  mounted() {
    this.$store.dispatch('data/fetchCountries').then(data => {
      this.countries = data;
    }).catch(error => {
      this.$toast.add({
        severity: 'error',
        summary: 'Error',
        detail: error,
        life: 3000
      });
    });
  },
  validations() {
    const idCardNumber = (value) => /^[0-9]{3,7}[AaBbGgHhLlMmPpZz]/.test(value);
    const date = (value) => value instanceof Date || /\d{2}\/\d{2}\/\d{4}/.test(value);

    const validMobileNumber = (value) => {
      if (this.mobileNumberCountryCode === '+356') return /^[1-9][0-9]{7}/.test(value)
      else return /^[0-9]{6,}/.test(value)
    }

    return {
      idCardNumber: {
        required,
        idCardNumber,
        $autoDirty: true,
      },
      title: {
        required,
        $autoDirty: true,
      },
      firstName: {
        required,
        $autoDirty: true,
      },
      lastName: {
        required,
        $autoDirty: true,
      },
      dateOfBirth: {
        required,
        date,
        $autoDirty: true,
      },
      streetAddress: {
        required,
        $autoDirty: true,
      },
      town: {
        required,
        $autoDirty: true,
      },
      postCode: {
        required,
        $autoDirty: true,
      },
      mobileNumber: {
        required,
        numeric,
        validMobileNumber,
        $autoDirty: true,
      },
      emailAddress: {
        email,
        $autoDirty: true,
      },
      photo: {
        required,
      }
    };
  },
  methods: {
    padIdCardNumber() {
      this.v$.idCardNumber.$touch();
      if (this.v$.idCardNumber.$error) {
        return;
      }
      this.idCardNumber = this.idCardNumber.padStart(8, '0');
    },
    sendCompleteIfSet(prefix, number) {
      if ((number === null) || (number === '')) {
        return null;
      }
      return `${prefix} ${number}`;
    },
    onCancel() {
      this.$confirm.require({
        message: 'Are you sure you want to cancel?',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.$router.push({name: 'affiliatedGroupAdminListMembers'});
        },
        reject: () => {
          // do nothing
        }
      });
    },
    onSubmit() {
      this.v$.$touch();
      if (this.v$.$error || this.insuranceRequiredButNotSelected || (this.insuredElsewhere && this.insuredElsewhereName === '')) {
        return;
      }

      const request = {
        personalDetails: {
          idCardNumber: this.idCardNumber,
          title: this.title,
          firstName: this.firstName,
          lastName: this.lastName,
          dateOfBirth: this.dateOfBirth,
          streetAddress: this.streetAddress,
          town: this.town,
          postCode: this.postCode,
          mobileNumber: this.sendCompleteIfSet(this.mobileNumberCountryCode, this.mobileNumber),
          emailAddress: this.emailAddress,
          photo: this.photo,
        },
        insurance: {
          thirdParty: this.thirdPartyInsurance,
          personalAccident: this.personalAccidentInsurance,
          insuredElsewhere: {
            hasInsuranceElsewhere: this.insuredElsewhere,
            insuranceElsewhereAgencyName: this.insuredElsewhereName,
          }
        }
      }

      this.$store.dispatch('admin/addNewMember', request).then(_resp => {
        this.$toast.add({
          severity: 'success',
          summary: 'Member added',
          detail: 'New member was added successfully.',
          life: 3000
        });
        setTimeout(() => {
          this.$router.push({name: 'affiliatedGroupAdminListMembers'});
        }, 1500);
      }).catch(error => {
        this.$toast.add({
          severity: 'error',
          summary: 'Error',
          detail: error,
          life: 3000
        });
      });
    }
  }
}
</script>
