<template>
  <div class="p-grid">
    <Fieldset legend="Insurance">
      <div class="p-field-checkbox">
        <Checkbox name="thirdPartyInsurance"
                  id="thirdPartyInsurance"
                  :binary="true"
                  :disabled="insuredElsewhere"
                  v-model="thirdPartyInsurance"/>
        <label for="thirdPartyInsurance">Third Party Liability - €14.00</label>
      </div>
      <div class="p-field-checkbox">
        <Checkbox name="personalAccidentInsurance"
                  id="personalAccidentInsurance"
                  :binary="true"
                  :disabled="insuredElsewhere"
                  v-model="personalAccidentInsurance"/>
        <label for="personalAccidentInsurance">Personal Accident - €5.00</label>
      </div>
      <div class="p-field-checkbox">
        <Checkbox name="insuredElsewhere"
                  id="insuredElsewhere"
                  :binary="true"
                  v-model="insuredElsewhere"/>
        <label for="insuredElsewhere">Insured Elsewhere</label>
        <InputText id="insuredElsewhereName"
                   :disabled="!insuredElsewhere"
                   type="text"
                   class="p-ml-2"
                   v-model.trim="insuredElsewhereName"/>
      </div>
      <small v-if="insuredElsewhere && insuredElsewhereName === ''" class="p-error">
        Please specify the name of the insurance agency.
      </small>
    </Fieldset>
  </div>
  <Divider/>
  <div align="right" class="p-pt-2">
    <Button label="Renew" @click="onRenew"/>
  </div>
</template>

<script>
export default {
  name: 'AffiliatedGroupAdminRenewInsurance',
  props: {
    member: {
      type: Object,
      default: null
    },
  },
  emits: ['error', 'infoMessage', 'closeMe'],
  data() {
    return {
      insuredElsewhere: false,
      insuredElsewhereName: '',
      thirdPartyInsurance: true,
      personalAccidentInsurance: false,
    };
  },
  watch: {
    insuredElsewhere: function (selected) {
      if (selected) {
        this.thirdPartyInsurance = false;
        this.personalAccidentInsurance = false;
      } else {
        this.thirdPartyInsurance = true;
      }
    }
  },
  methods: {
    onRenew() {
      if (this.insuredElsewhere && this.insuredElsewhereName === '') {
        return;
      }

      const today = new Date();
      const req = {
        membershipNumber: this.member.membershipNumber,
        memberID: this.member.id,
        year: `${today.getFullYear()}`,
        insurance: {
          thirdParty: this.thirdPartyInsurance,
          personalAccident: this.personalAccidentInsurance,
          insuredElsewhere: {
            hasInsuranceElsewhere: this.insuredElsewhere,
            insuranceElsewhereAgencyName: this.insuredElsewhereName,
          }
        }
      }
      this.$store.dispatch('admin/renewAffiliatedGroupMemberMembership', req).then(_resp => {
        this.$emit('infoMessage', {
          title: 'Renewal successful',
          message: 'Insurance was renewed successfully.'
        })
      }).catch(error => {
        this.$emit('error', error);
      });
      this.$emit('closeMe');
    }
  }
}
</script>
